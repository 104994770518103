import { SlotConfig } from "./d";

const DEFAULT_DATA_NETWORK: SlotConfig[] = [
  {
    id: "7b3cc7d8-1a6a-41fd-ae57-dae5c4c80bd1",
    name: "Pachinca Dice",
    codename: "p7-050s-pachinca-dice",
  },
  {
    id: "204466d9-f59c-44a1-869a-4e28f9d7cf1f",
    name: "The Wild Four Dice",
    codename: "p7-049s-the-wild-four-dice",
  },
  {
    id: "2f241e18-db32-406d-a6ba-1b909e8555c0",
    name: "Golden Pirate Dice",
    codename: "p7-048s-golden-pirate-dice",
  },
  {
    id: "1c00895e-f7c2-4fcd-b6ad-b680386d23b3",
    name: "Sakura Fantasia Dice",
    codename: "p7-047s-sakura-fantasia-dice",
  },
  {
    id: "f96a4886-a701-41a7-9c90-f248003de888",
    name: "AGE OF EXPLORERS",
    codename: "p7-027s-age-of-explorers",
  },
  {
    id: "508bb990-dac3-4cab-ba15-6789391a5e85",
    name: "NAUGHTY JOKER",
    codename: "p7-030s-naughty-joker",
  },
  {
    id: "09bf2f76-f14f-43db-8630-6e17cc0dd6f2",
    name: "WOLF AND SHEEP",
    codename: "p7-029s-wolf-and-sheep",
  },
  {
    id: "2ff24482-057f-4820-8bb1-b2d6e30961b7",
    name: "BOOK OF ODIN",
    codename: "p7-034s-book-of-odin",
  },
  {
    id: "dabe3cb6-c28d-41ad-8bba-1d54f82567fc",
    name: "METEOR OF CHAOS DICE",
    codename: "p7-042s-dice-of-chaos",
  },
  {
    id: "e3665667-fbf8-4ca1-801d-f900520ba3a9",
    name: "MYSTIC SNOWFLAKES",
    codename: "xt-004s-mystic-snowflakes",
  },
  {
    id: "7594caf8-06f0-4f88-8162-a320470c4a23",
    name: "IZAKAYA",
  },
  {
    id: "d0980ee6-51c4-428d-8491-412da8ff2550",
    name: "MRFIRST SAKURA",
    codename: "p7-037s-mrfirst-sakura",
  },
  {
    id: "643ffb38-a83c-4af3-aaec-fc4928a88321",
    name: "SHIBAS BIRTHDAY",
  },
  {
    id: "a541fd31-6ee1-4837-8420-ec5927dd6303",
    name: "HIT THE MOLE",
  },
  {
    id: "80d4ecd9-f75e-48f8-9147-ac51fedbb74e",
    name: "BUZZING BEES",
  },
  {
    id: "6906750c-f4ee-4602-80cb-470b853699fb",
    name: "TOMATO FESTIVAL",
  },
  {
    id: "f93898ef-5635-4276-af71-c417173572da",
    codename: "p7-011s-african-animals",
    name: "AFRICAN ANIMALS",
  },
  {
    id: "1560c13f-4707-494d-922b-b49dd1dfacf1",
    codename: "p7-010s-samurai-rumble",
    name: "SAMURAI RUMBLE",
  },
  {
    id: "812327ed-d1fe-4654-b7c6-8fc020b91325",
    codename: "p7-016s-cardmaster",
    name: "THE CARDMASTER",
  },
  {
    id: "ab23256b-992d-4bd0-8089-4aa1d560797b",
    name: "SAKURA FANTASIA",
  },
  {
    id: "680ba83d-9c72-4bac-a465-9b4966716350",
    name: "FLUFFY STAR",
  },
  {
    id: "435c7b5b-0f88-43d7-bb87-a1ce603b8fa4",
    codename: "p7-021s-fujin-raijin",
    name: "FUJIN AND RAIJIN",
  },
  {
    id: "e2187e05-b4bb-4d6b-8c88-c3fd4f03c083",
    codename: "p7-015s-dragon",
    name: "AWAKENING OF THE DRAGON",
  },
  {
    id: "18f3ab70-4155-401c-862d-7c5099e692f6",
    codename: "p7-008s-ninja-strike",
    name: "NINJA STRIKE",
  },
  {
    id: "92d46cfe-6399-4132-9246-643d36ad4b7e",
    codename: "p7-018s-bookofwaifu",
    name: "BOOK OF WAIFU",
  },
  {
    id: "db8f3359-8589-45cc-9409-d7b5c6801764",
    name: "PACHINCA",
    codename: "xt-002s-pachinca",
  },
  {
    id: "45069551-28bb-4af5-8965-2dea77488f3e",
    codename: "p7-012s-hammer-thunder",
    name: "HAMMER & THUNDER",
  },
  {
    id: "eb66909f-c5c5-4b39-aa20-311cf6952caf",
    codename: "p7-014s-pachicroon",
    name: "PACHI CROON",
  },
  {
    id: "1f3d26dd-e3f5-4e7c-8b32-b618e335377d",
    codename: "p7-017s-azai",
    name: "AZAI SISTERS",
  },
  {
    id: "eba7f94f-c50c-493d-bedb-476e41061b8a",
    codename: "p7-005s-paradise-delight",
    name: "PARADISE DELIGHT",
  },
  {
    id: "9776a55d-ac49-401b-9a9e-691b4b2c7683",
    codename: "p7-009s-meteor-of-chaos",
    name: "METEOR OF CHAOS",
  },
  {
    id: "ef804251-8f17-443e-ac4e-7f42c9b1caae",
    codename: "p7-013s-mega-heist",
    name: "SPIN HEIST",
  },
  {
    id: "41a1ac68-f793-4b0b-b833-0c68e5c431c7",
    codename: "p7-007s-wild-four",
    name: "THE WILD FOUR ",
  },
  {
    id: "ae830e1b-268f-4154-aabd-46732a66d99b",
    codename: "p7-006s-dual-fusion",
    name: "LUCKY DUAL FUSION",
  },
  {
    id: "a7c653c1-d9cb-4e7b-982e-6edde5d38760",
    codename: "xt-001s-dazzling-box",
    name: "DAZZLING BOX",
  },
  {
    id: "77b701b7-5210-4cfa-9327-d6d3ab9f3317",
    codename: "p7-004s-golden-pirate",
    name: "GOLDEN PIRATE",
  },
  {
    id: "977a152e-188e-49f8-b075-4c2c51f5fb30",
    codename: "p7-003s-shark-rush",
    name: "SHARK RUSH",
  },
  {
    id: "5a6aacac-18c4-4a03-9457-98544dd385c6",
    name: "THE FORBIDDEN PYRAMID",
    codename: "p7-002s-forbidden-pyramid",
  },
  {
    id: "e98ecbf6-03ea-4ada-9e15-3da6d85fc257",
    codename: "p7-001s-mai-tai-punch",
    name: "MAI TAI PUNCH",
  },
];

const getData = async (): Promise<SlotConfig[]> => {
  // const response = await fetch("/api/external/game/list", {
  //   headers: {
  //     "content-type": "application/json",
  //   },
  // });
  // const { gameList }: JSONResponse2 = await response.json();

  return DEFAULT_DATA_NETWORK;
};

const createGameUrl = async (slotId: string) => {
  const response = await fetch(
    `/api/external/game/start?gameId=${slotId}&amount=1000000`,
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );

  const { url }: { url: string } = await response.json();
  return url;
};

export { getData, createGameUrl };
